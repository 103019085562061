.gradient_background {
  background: linear-gradient(180deg, rgba(0, 98, 255, 0), #141212);
  width: -webkit-fill-available;
  height: 100%;
}

.backgroundHeader {
  width: -webkit-fill-available;
  /* height: 28rem; */
}

.restrictHeight {
  height: 100%;
}

@media screen and (min-width: 448px) {
  /* CSS rules to apply when the screen width is at least 448px */
  /* For example: */
  .backgroundHeader {
    max-width: 28rem;
    height: 28rem;
  }

  .gradient_background {
    width: 28rem;
    height: 100%;
  }

  .restrictHeight {
    height: 28rem;
  }
}
