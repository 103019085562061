.wave {
  display: block;
  position: relative;
  height: 40px;
  width: 100%;
  background: rgb(57, 27, 112);
  transform: scale(1, 1.5);
}

.wave:before {
  content: '';
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 300px;
  background-color: white;
  right: -25%;
  top: 20px;
}

.wave:after {
  content: '';
  display: block;
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 300px;
  background-color: rgb(57, 27, 112);
  left: -25%;
  top: -240px;
  clip-path: ellipse(100% 15% at -15% 100%);
}
