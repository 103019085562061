@media screen and (min-width: 448px) {
  .post-content {
    width: 28rem;
    /* height: 35rem; */
  }
  .large-content {
    height: 35rem;
  }
}

.shots-full {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
}
